<template>
  <b-container
    fluid
    class="bootstrap bg-white"
  >
    <div class="card text-white pb-5">
      <div class="header-desktop" />
      <div class="card-img-overlay">
        <b-container>
          <b-row>
            <b-col
              lg="2"
              class="d-none d-lg-block"
            >
            </b-col>
            <b-col
              xl="8"
              lg="10"
              cols="12"
              class="text-center mt-md-5"
            >
              <b-card-title class="mt-4 mt-md-0">
                <h1>{{ $i18n('startpage.welcome_text') }}</h1>
              </b-card-title>
              <b-card-text class="mt-4">
                <h3>{{ $i18n('startpage.slogan_1') }}</h3>
              </b-card-text>
              <b-card-text class="mt-5 text-center">
                <b-button
                  variant="secondary"
                  class="pl-5 pr-5"
                  :href="$url('joininfo')"
                >
                  {{ $i18n('startpage.call_to_action_button') }}
                </b-button>
              </b-card-text>
              <h4
                class="d-none d-lg-block"
              >
                <b>{{ $i18n('startpage.slogan_2') }}</b><br>
                {{ $i18n('startpage.slogan_3') }}
              </h4>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <b-row
      v-if="contentBlockVisible"
      class="ml-3 ml-md-5"
    >
      <b-col
        cols="12"
        md="12"
        xl="3"
        class="text-xl-right"
      >
        <span v-html="contentBlock1" />
      </b-col>
      <b-col
        cols="12"
        xl="5"
        md="6"
      >
        <span v-html="contentBlock2" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <span v-html="contentBlock3" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { MAP_ATTRIBUTION } from '@/consts'

export default {
  components: {
  },
  props: {
    contentBlock1: { type: String, default: '' },
    contentBlock2: { type: String, default: '' },
    contentBlock3: { type: String, default: '' },
    country: { type: String, default: 'DE' },
  },
  computed: {
    currentCountry () {
      if (this.country === 'AT') {
        return this.$url('communitiesAustria')
      } else if (this.country === 'CH') {
        return this.$url('communitiesSwitzerland')
      } else {
        return this.$url('communitiesGermany')
      }
    },
    contentBlockVisible () {
      return this.contentBlock1 && this.contentBlock2 && this.contentBlock1
    },
    MapAttribution () {
      return MAP_ATTRIBUTION
    },
  },
}

</script>

<style lang="scss" scoped>

.row {
  margin-left: 0px;
  margin-right: 0px;
}

::v-deep .link {
  color: var(--fs-green);
  font-weight: bold;
}

.line {
  border-bottom: 1px solid var(--fs-light-grey);
  width: 90%;
  margin: 0 auto;
}

svg {
  height: auto;
  width: 14em;
}

.embed-responsive {
  max-width: 80em;
  margin: 0 auto;
}

.teaser-towns {
  border-radius: 0px !important;
}

.teaser-background {
  background-color: var(--fs-beige);
  max-width: 805px;
  max-height: 451px;
}

h5 {
  font-family: "Alfa Slab One", serif;
  color: #333333;
  font-size: 1.375rem;
  letter-spacing: 0.05em;
}

::v-deep h4 {
  color: #333333;
  font-size: 1rem;
}

::v-deep h3 {
  color: #333333;
  font-weight: bold;
}

::v-deep h2 {
  font-family: "Alfa Slab One", serif;
  color: #333333;
  font-weight: normal;
  letter-spacing: 0.05em;
}

::v-deep h1 {
  font-family: "Alfa Slab One", serif;
  color: #333333;
  font-weight: normal;
  letter-spacing: 0.05em;
}

/*
Extra small devices (portrait phones, less than 544px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/
::v-deep h1 {font-size:1.6rem;} /*1rem = 16px*/
::v-deep h2 {font-size:1.4rem;} /*1rem = 16px*/
::v-deep h3 {font-size:1rem;} /*1rem = 16px*/

.call-to-action-image {
  background: #fff url(/img/startpage/mach-mit-desktop-544@1.5.png) center center no-repeat;
  height: 47rem;
}

.header-desktop {
  background: #fff url(/img/startpage/header-desktop-544.png) center center no-repeat;
  min-height: 23rem;
}

.teaser-map {
  background: #fff url(/img/startpage/karte-544@1.5.png) center center no-repeat;
  height: 32rem;
}

.teaser-donate {
  background: #fff url(/img/startpage/spenden-544.png) center center no-repeat;
  min-height: 22rem;
}

.map-copyright {
  color: #333333;
  text-align: center;
  z-index: 1;
}
/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
  ::v-deep h1 {font-size:1.5rem;} /*1rem = 16px*/
  ::v-deep h2 {font-size:1.35rem;} /*1rem = 16px*/
  ::v-deep h3 {font-size:1.25rem;} /*1rem = 16px*/

  .call-to-action-image {
    background: #fff url(/img/startpage/mach-mit-desktop-786.png) center center no-repeat;
    height: 36rem;
  }

  .header-desktop {
    background: #fff url(/img/startpage/header-desktop-789.png) center center no-repeat;
    min-height: 23rem;
  }

  .teaser-map {
    background: #fff url(/img/startpage/karte-786.png) center center no-repeat;
    height: 32rem;
  }

  .teaser-donate {
    background: #fff url(/img/startpage/spenden-786.png) center center no-repeat;
    min-height: 22rem;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  ::v-deep h1 {font-size:2rem;} /*1rem = 16px*/
  ::v-deep h2 {font-size:1.75rem;} /*1rem = 16px*/
  ::v-deep h3 {font-size:1.5rem;} /*1rem = 16px*/

  .call-to-action-image {
    background: #fff url(/img/startpage/mach-mit-desktop-992@1.5.png) center center no-repeat;
    height: 33.5rem;
  }

  .header-desktop {
    background: #fff url(/img/startpage/header-desktop-992.png) center center no-repeat;
    min-height: 23rem;
  }

  .teaser-map {
    background: #fff url(/img/startpage/karte-992.png) center center;
    height: 25rem;
  }

  .teaser-donate {
    background: #fff url(/img/startpage/spenden-992.png) center center no-repeat;
    max-height: 14rem;
  }

  .map-copyright {
    text-align: right;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  ::v-deep h1 {font-size:2.5rem;} /*1rem = 16px*/
  ::v-deep h2 {font-size:2rem;} /*1rem = 16px*/
  ::v-deep h3 {font-size:1.75rem;} /*1rem = 16px*/

  .call-to-action-image {
    background: #fff url(/img/startpage/mach-mit-desktop-1200@1.5.png) center center no-repeat;
    height: 30rem;
  }

  .header-desktop {
    background: #fff url(/img/startpage/header-desktop-1200.png) center center no-repeat;
    min-height: 23rem;
  }

  .teaser-map {
    background: #fff url(/img/startpage/karte-1200.png) center center;
    height: 25rem;
  }

  .teaser-donate {
    background: #fff url(/img/startpage/spenden-1200.png) center center no-repeat;
    max-height: 14rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  ::v-deep h1 {font-size:2.25rem;} /*1rem = 16px*/
  ::v-deep h2 {font-size:2rem;} /*1rem = 16px*/

  .call-to-action-image {
    background: #fff url(/img/startpage/mach-mit-desktop-1200@2x.png) center center no-repeat;
    height: 25rem;
  }

  .header-desktop {
    background: #fff url(/img/startpage/header-desktop-1200@2x.png) center center no-repeat;
    min-height: 23rem;
  }

  .teaser-map {
    background: #fff url(/img/startpage/karte-1200@2x.png) center center;
    height: 25rem;
  }

  .teaser-donate {
    background: #fff url(/img/startpage/spenden-1200@2x.png) center center no-repeat;
    max-height: 23rem;
  }
}
</style>

<style>
.bootstrap .img-news {
  max-width: 55%;
  height: auto;
}

div#main {
  min-height: unset !important;
  padding-bottom: unset !important;
}
</style>
